import React from "react";
import StyledCheckout from "../StyledCheckout";
import UserDetailsForm from "../UserDetailsForm";

function ShippingDetails({ user, setUser }) {
  return (
    <div>
      <StyledCheckout.SectionTitle>
        SHIPPING DETAILS
      </StyledCheckout.SectionTitle>

      <StyledCheckout.ShippingDetailsDiv>
        <StyledCheckout.ShippingDetailsTopRow>
          <p>{user?.name}</p>
          <UserDetailsForm setUser={setUser} />
        </StyledCheckout.ShippingDetailsTopRow>

        <p>{user?.address}</p>
        <p>{user?.email}</p>
        <p>{user?.phone_number}</p>
      </StyledCheckout.ShippingDetailsDiv>
    </div>
  );
}

export default ShippingDetails;
