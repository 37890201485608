import React from "react";
import { paymentMethods } from "../../../utils/constants";
import BraintreeChackoutForm from "../braintreeCheckout/BraintreeChackoutForm";
import StripeCheckout from "../stripeCheckout/StripeCheckout";
import StyledCheckout from "../StyledCheckout";
import PaypalCheckoutButton from "../paypal/PaypalCheckoutButton";
import BluesnapCheckout from "../bluesnap/BluesnapCheckout";
import AdyenCheckoutPage from "../adyen/AdyenCheckoutPage";

function PaymentDetails({
  paymentMethod,
  clientSecret,
  amount,
  priceChange,
  selectedPaypalPaymentOption,
}) {
  return (
    <div>
      <StyledCheckout.SectionTitle>PAYMENT DETAILS</StyledCheckout.SectionTitle>
      {paymentMethod === paymentMethods.stripe ? (
        <StripeCheckout
          clientSecret={clientSecret}
          paymentMethod={paymentMethod}
          amount={amount}
        />
      ) : paymentMethod === paymentMethods.braintree ? (
        <BraintreeChackoutForm
          clientSecret={clientSecret}
          paymentMethod={paymentMethod}
          priceChange={priceChange}
        />
      ) : paymentMethod === paymentMethods.paypal ? (
        <PaypalCheckoutButton
          selectedPaypalPaymentOption={selectedPaypalPaymentOption}
        />
      ) : paymentMethod === paymentMethods.bluesnap ? (
        <BluesnapCheckout />
      ) : (
        <AdyenCheckoutPage />
      )}
    </div>
  );
}

export default PaymentDetails;
