import React from "react";
import { formatOptions, paymentMethods } from "../../../utils/constants";
import { formatPrice } from "../../../utils/helperFunctions";
import StyledCheckout from "../StyledCheckout";
import { Rate } from "antd";

function YourOrder({ selectedProduct, amount, paymentMethod }) {
  return (
    <StyledCheckout.YourOrderDiv>
      <StyledCheckout.SectionTitle>YOUR ORDER</StyledCheckout.SectionTitle>

      <StyledCheckout.YourOrderInnerDiv>
        <img src={selectedProduct?.thumbnail} alt="product-thumbnail" className="w-1/4 mt-4" />
        <div className="space-y-2 w-full mt-4">
          <p>{selectedProduct?.title}</p>
          <p>{selectedProduct?.brand}</p>
          <div className="w-full flex items-center justify-between">
            {paymentMethod === paymentMethods.paypal ? (
              <p>${formatPrice(selectedProduct?.price, formatOptions.display)}</p>
            ) : (
              <p>${formatPrice(amount, formatOptions.devide)}</p>
            )}
          </div>
          <Rate disabled allowHalf value={selectedProduct?.rating} />
        </div>
      </StyledCheckout.YourOrderInnerDiv>
    </StyledCheckout.YourOrderDiv>
  );
}

export default YourOrder;
