import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";

const StyledPaymentCompletion = {
  Container: tw.div`
  w-full h-screen flex-col justify-center bg-white
  `,
  InnerContainer: tw.div`
w-1/2 h-2/3 
flex flex-col items-center justify-center space-y-5 -mt-40
`,
  Result: tw.div`
text-xl text-white text-center p-1
${(p) => (p.status ? "bg-green-400 " : "bg-red-500 ")}

w-full 
`,
  Spinner: tw.div`
w-full h-full flex items-center justify-center
`,
  BackToCatalouge: {
    Container: tw(Link)`
  w-full 
  flex items-center space-x-2 
  text-xs text-teal-500
  cursor-pointer 
  transition ease-in-out hover:text-black duration-300
  `,
  },
};

export default StyledPaymentCompletion;
