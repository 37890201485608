import React, { useEffect, useState } from "react";
import Product from "./Product";
import StyledProducts from "./StyledProducts";
import {
  onetimePaymentProducts,
  subscriptionItems,
} from "../../utils/constants";
import "./Products.css";

function Products({ setLoading }) {
  const [products, setProducts] = useState([]);
  const combinedProducts = onetimePaymentProducts.concat(subscriptionItems);

  useEffect(() => {
    setProducts(combinedProducts);
  }, []);

  return (
    <StyledProducts.ProductsContainer>
      {React.Children.toArray(
        products?.map((product, i) => (
          <Product product={product} index={i} setLoading={setLoading} />
        ))
      )}
    </StyledProducts.ProductsContainer>
  );
}

export default Products;
