import React from "react";
import StyledCheckout from "../StyledCheckout";
import { cardNumberDisplay } from "../../../utils/helperFunctions";

function CheckoutCreditCard({ selectedCard }) {
  return (
    <StyledCheckout.CreditCard>
      {Object.keys(selectedCard).length > 0 && (
        <StyledCheckout.CreditCardTitle>
          BouncePay Card
        </StyledCheckout.CreditCardTitle>
      )}
      <StyledCheckout.CreditCardDetailsDiv>
        {Object.keys(selectedCard).length > 0 ? (
          <div className="text-xs xl:text-base space-y-4">
            <div className="pr-24">
              {cardNumberDisplay(selectedCard.card_number)}
            </div>
            <div className="flex space-x-10 ">
              <p>{selectedCard?.expiration_date}</p>
              <p>{selectedCard?.cvc}</p>
              <img
                src="https://vectorlogo4u.com/wp-content/uploads/2020/10/mastercard-logo-01.png"
                alt=""
                className="w-24 xl:w-32 absolute bottom-1 xl:bottom-3 -right-5"
              />
            </div>
          </div>
        ) : (
          <p>Please choose a card from the list above</p>
        )}
      </StyledCheckout.CreditCardDetailsDiv>
    </StyledCheckout.CreditCard>
  );
}

export default CheckoutCreditCard;
