import React, { useState } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { useEffect } from "react";
import {
  getAdyenPaymenDetails,
  getAdyenSessionToken,
} from "../../../utils/apiCalls";
import Spinner from "../../../components/spinner/Spinner";
import {
  loadProduct,
  loadRandomUser,
  showErrorMessage,
} from "../../../utils/helperFunctions";

function AdyenCheckoutPage() {
  const [loading, setLoading] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(null);

  const adyenClientKey = process.env.REACT_APP_ADYEN_CLIENT_KEY;
  const product = loadProduct();
  const user = loadRandomUser();

  useEffect(() => {
    getAdyenSessionToken(setSessionDetails, product, user, setLoading);
  }, []);
  const configuration = {
    environment: "test",
    clientKey: adyenClientKey,
    showPayButton: true,
    session: {
      id: sessionDetails?.id,
      sessionData: sessionDetails?.sessionData,
    },
    onPaymentCompleted: (result, component) => {
      console.info(result, component);
      if (sessionDetails) {
        const data = {
          merchantAccount: sessionDetails?.merchantAccount,
          shopperReference: sessionDetails?.shopperReference,
          reference: sessionDetails?.reference,
          price: sessionDetails?.amount?.value,
          currency: sessionDetails?.amount?.currency,
          country: sessionDetails?.countryCode,
        };
        getAdyenPaymenDetails(
          sessionDetails?.merchantAccount,
          sessionDetails?.shopperReference,
          data
        );
      }
    },
    onError: (error) => {
      showErrorMessage(error.message);
    },
    // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
    // For example, this is 3D Secure configuration for cards:
  };

  const createCheckoutInstance = async () => {
    try {
      const checkout = await AdyenCheckout(configuration);

      if (checkout) {
        //Create an instance of the Component and mount it to the container you created.
        const cardComponent = checkout.create("card");

        if (cardComponent) {
          cardComponent.mount("#card-container");
        } else {
          showErrorMessage("Failed to create card component");
        }
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  useEffect(() => {
    if (adyenClientKey && sessionDetails) {
      createCheckoutInstance();
    }
  }, [sessionDetails]);

  return <>{loading ? <Spinner /> : <div id="card-container"></div>}</>;
}

export default AdyenCheckoutPage;
