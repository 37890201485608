import tw from "tailwind-styled-components";

const StyledPageStep = {
  Container: tw.div`
  w-full flex items-center justify-center 
  font-normal text-sm
  `,

  InnerContainer: tw.div`
  w-72 flex justify-between items-center 
  -mt-16
  `,
};

export default StyledPageStep;
