import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

function StripeCheckout({ clientSecret, paymentMethod, amount }) {
  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        amount={amount}
        clientSecret={clientSecret}
        paymentMethod={paymentMethod}
      />
    </Elements>
  );
}

export default StripeCheckout;
