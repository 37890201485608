import tw from "tailwind-styled-components";
import { Spin } from "antd";

const StyledCatalogue = {
  Container: tw.div`
  w-full h-screen flex-col 
  overflow-y-scroll 
  bg-white 
`,
  GreenHeader: tw.div`
  w-full h-60 flex items-center 
  bg-teal-500 rounded-b-full 
  `,
};

export default StyledCatalogue;
