import { Input } from "antd";
import tw from "tailwind-styled-components";

const StyledCheckout = {
  Container: tw.div`
  `,

  CheckoutContainer: tw.div`
  w-full h-screen 
  px-40 py-20 bg-white
  `,

  CheckoutInnerContainer: tw.div`
  w-full h-full
  `,

  CheckoutTitle: tw.p`
  w-full 
  text-2xl text-gray-600 font-medium tracking-wide
  pb-5
  `,

  CheckoutDetailsContainer: tw.div`
  w-full h-full flex flex-col lg:flex-row-reverse justify-between 
  `,

  LeftContainer: tw.div`
  w-full xl:w-[45%] flex flex-col 
  space-y-16 
  font-normal text-white
  `,
  RightContainer: tw.div`
  w-full xl:w-[45%] flex flex-col 
  space-y-10 
  font-semibold text-gray-600
  `,

  Label: tw.label`
  text-xs font-semibold text-gray-600
  `,

  SectionTitle: tw.p`
  w-full border-b-2 border-gray-300 text-xs text-gray-400 font-semibold tracking-wide pb-2
  `,

  CreditCard: tw.div`
  mt-4 drop-shadow-lg w-60 xl:w-80 h-32 xl:h-44 rounded-2xl bg-teal-500 flex relative
  `,

  CreditCardTitle: tw.p`
  absolute top-0 right-0 p-5 font-semibold text-sm xl:text-base tracking-wide
  `,

  CreditCardDetailsDiv: tw.div`
  w-full h-1/2 space-y-3 self-end pl-5 relative 
  `,

  CopySection: tw.div`
  w-full flex xl:flex-col ml-10 xl:h-44 mt-4 xl:space-y-6 pt-1 text-gray-600 font-semibold 
  `,

  CopyItem: tw.div`
  w-full flex items-center justify-center xl:justify-start space-x-1 cursor-pointer
  `,

  CopyItemText: tw.p`
  m-0 text-xs xl:text-sm
  `,

  PayNowButtonContainer: tw.div`
  flex flex-col items-center justify-center 
  space-y-5 mt-5
  `,

  PayButton: tw.button`
  w-full mt-3 p-3 rounded-md hover:shadow-xl 
  font-semibold text-sm shadow-md
  transition-all ease-in-out duration-200
  ${(p) => (p.$disabled ? "bg-gray-300" : "bg-teal-500")}
  `,

  ShippingDetailsDiv: tw.div`
   w-full 
   mt-4 space-y-2
  `,

  ShippingDetailsTopRow: tw.div`
  w-full flex items-center justify-between
  `,

  YourOrderDiv: tw.div`font-semibold text-gray-600`,

  YourOrderInnerDiv: tw.div`flex items-center space-x-10`,

  CheckoutHeaderContainer: tw.div`
  flex justify-between 
  mb-16 py-5 
  shadow
  `,
};

export default StyledCheckout;
