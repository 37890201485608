import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { paypalCaptureDeclinedOrder, paypalCaptureOrder } from "../../../utils/apiCalls";
import PaymentProccessed from "../../paymentCompletion/PaymentProccessed";
import Spinner from "../../../components/spinner/Spinner";
import { paymentStatuses } from "../../../utils/constants";
import { loadPaypalPaymentOption } from "../../../utils/helperFunctions";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function ProcessingPayment() {
  const [showPaymentProccessed, setShowPaymentProccessed] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const query = useQuery();
  const token = query.get("token");
  const selectedPaymentOption = loadPaypalPaymentOption();

  useEffect(() => {
    const approvedPayment = selectedPaymentOption?.status === paymentStatuses.approved;
    if (token) {
      if (approvedPayment) {
        paypalCaptureOrder(token, setOrderStatus, setTransactionId, setShowPaymentProccessed);
      } else {
        const declineCode = selectedPaymentOption?.application_code;
        paypalCaptureDeclinedOrder(token, setOrderStatus, setTransactionId, setShowPaymentProccessed, declineCode);
      }
    }
  }, [token]);

  return (
    <>{showPaymentProccessed ? <PaymentProccessed orderStatus={orderStatus} transactionId={transactionId} /> : <Spinner />}</>
  );
}

export default ProcessingPayment;
