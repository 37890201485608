export const apiUrl = process.env.REACT_APP_BACKEND_HOST;
export const stripeRedirectUrl = process.env.REACT_APP_STORE_URL;

export const productTypes = {
  subscription_with_trial: "subscription-with-trial",
  subscription: "subscription",
  checkout: "checkout",
};

export const paymentMethods = {
  stripe: "stripe",
  braintree: "braintree",
  paypal: "paypal",
  bluesnap: "bluesnap",
  adyen: "adyen",
};

export const paymentMethodOptions = [
  {
    value: "stripe",
    label: "Stripe",
  },
  {
    value: "braintree",
    label: "Braintree",
  },
  {
    value: "paypal",
    label: "Paypal",
  },
  {
    value: "bluesnap",
    label: "Bluesnap",
  },
  {
    value: "adyen",
    label: "Adyen",
  },
];

export const paypalPaymentsOptions = [
  {
    name: "Approved payment",
    status: "approved",
  },
  {
    name: "Instrument Declined",
    status: "declined",
    application_code: "INSTRUMENT_DECLINED",
    description:
      "The instrument presented was either declined by the processor or bank or it can't be used for this payment.",
  },
  {
    name: "Transaction Refused",
    status: "declined",
    application_code: "TRANSACTION_REFUSED",
    description: "The request was refused.",
  },
  {
    name: "Compliance Violation",
    status: "declined",
    application_code: "COMPLIANCE_VIOLATION",
    description: "Transaction is declined due to compliance violation.",
  },
  {
    name: "Payer Cannot Pay",
    status: "declined",
    application_code: "PAYER_CANNOT_PAY",
    description:
      "The payer cannot pay for this transaction. Contact the payer to find other ways to pay for this transaction.",
  },
];

export const formatOptions = {
  display: "display",
  devide: "devide",
  multiply: "multiply",
  payButton: "payButton",
};

export const actionButtonsText = {
  card_number: "Card number",
  exp_date: "Exp date",
  cvc: "CVC",
};

export const defaultCardValues = {
  card_number: "4444444444444444",
  expiration_date: "12/33",
  cvc: 123,
};

export const cardsListVerificationResponses = {
  approved: "Transaction Approved" || "Valid card",
};

export const onetimePaymentProducts = [
  {
    thumbnail:
      "https://images.unsplash.com/photo-1512374382149-233c42b6a83b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTl8fHNob2VzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    title: "HIGH TOPS WHITE",
    rating: 4.7,
    price: 229,
    currency: "USD",
    product_type: productTypes.checkout,
    trial_period: false,
    description: "One time payment item",
    id: "payment-button-checkout",
  },
];

export const subscriptionItems = [
  {
    thumbnail:
      "https://images.unsplash.com/photo-1637363990764-de84fd247b7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG5ldGZsaXh8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    title: "NETFLIX - FREE TRIAL!",
    rating: 4.7,
    price: 9,
    currency: "USD",
    product_type: productTypes.subscription_with_trial,
    trial_period: true,
    description: "Lingopie subscription with trial",
    lingopie: true,
    blockBraintree: true,
    id: "payment-button-subscription-with-trial",
  },
  {
    thumbnail:
      "https://images.unsplash.com/photo-1623428187969-5da2dcea5ebf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTI2fHxzYWxhZHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    title: "HELLO FRESH - START TODAY!",
    rating: 4.2,
    price: 159,
    currency: "USD",
    product_type: productTypes.subscription,
    trial_period: false,
    description: "Subscription without trial",
    blockBraintree: true,
    id: "payment-button-subscription",
  },
];

export const braintreePriceOptions = [
  {
    amount: "150",
    description: "Approved",
    approved: true,
  },
  {
    amount: "2500",
    description: "Processor",
    approved: true,
  },
  {
    amount: "2501",
    description: "Processor",
    approved: false,
  },
];

export const paymentStatuses = {
  approved: "approved",
  completed: "completed",
  declined: "declined",
  failed: "failed",
  error: "error",
};

export const scenarios = (paymentMethod) => {
  return paymentMethod === paymentMethods?.adyen
    ? [
        {
          label: "Credit card chosen",
          value: {
            price: 150,
            cardPosition: 0,
            description:
              "Use this card to create a failed and then successfull payment",
          },
        },
      ]
    : [
        {
          label: "Successful transaction",
          value: {
            price: 150,
            cardPosition: 0,
            description: "Successful transaction that wont require Bounce",
          },
        },
        {
          label: "Declined transaction declined by Bounce",
          value: {
            price: 2501,
            cardPosition: paymentMethod === paymentMethods.braintree ? 0 : 2,
            description:
              "Declined transaction that will not get approved by Bounce",
          },
        },
        {
          label: "Declined transaction approved by Bounce",
          value: {
            price: 2500,
            cardPosition: paymentMethod === paymentMethods.braintree ? 0 : 2,
            description:
              "Declined transaction that will get approved by Bounce",
          },
        },
        {
          label: "Disputed transaction",
          value: {
            price: 10,
            cardPosition: 3,
            description: "Disputed transaction",
          },
        },
      ];
};
