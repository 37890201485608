import React, { useEffect, useState } from "react";
import { getBluesnapToken } from "../../../utils/apiCalls";
import Spinner from "../../../components/spinner/Spinner";
import BluesnapCheckoutComponent from "./BluesnapCheckoutComponent";

function BluesnapCheckout() {
  const [bluesnapToken, setBluesnapToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBluesnapToken(setBluesnapToken, setLoading);
  }, []);

  return (
    <div className="min-h-min">
      {loading ? (
        <Spinner />
      ) : bluesnapToken ? (
        <BluesnapCheckoutComponent bluesnapToken={bluesnapToken} />
      ) : null}
    </div>
  );
}

export default BluesnapCheckout;
