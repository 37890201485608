import React, { useEffect, useState } from "react";
import Products from "../../components/products/Products";
import PageStep from "../../components/pageStep/PageStep";
import { getRandomUser } from "../../utils/apiCalls";
import StyledCatalogue from "./StyledCatalogue";
import Spinner from "../../components/spinner/Spinner";

function Catalogue() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRandomUser();
  });
  return (
    <StyledCatalogue.Container>
      {!loading ? (
        <>
          <StyledCatalogue.GreenHeader>
            <PageStep pageNumber={1} />
          </StyledCatalogue.GreenHeader>
          <Products setLoading={setLoading} />
        </>
      ) : (
        <Spinner />
      )}
    </StyledCatalogue.Container>
  );
}

export default Catalogue;
