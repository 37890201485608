import React from "react";
import { Spin } from "antd";

function Spinner({ small }) {
  return (
    <>
      {!small ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Spin size="medium" className="spinner" />
        </div>
      )}
    </>
  );
}

export default Spinner;
