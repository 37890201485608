import React, { useEffect, useState } from "react";
import StyledCheckout from "../StyledCheckout";
import { Radio } from "antd";
import { paypalPaymentsOptions } from "../../../utils/constants";
import { savePaypalPaymentOption } from "../../../utils/helperFunctions";

function PaypalScenarios() {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(paypalPaymentsOptions[0]);

  const handlePaymentOptionChange = (e) => {
    const selectedOption = paypalPaymentsOptions.find((option) => option.name === e.target.value);
    setSelectedPaymentOption(selectedOption);
  };

  useEffect(() => {
    savePaypalPaymentOption(selectedPaymentOption);
  }, [selectedPaymentOption]);

  return (
    <div className="space-y-3 text-gray-600">
      <StyledCheckout.SectionTitle>SCENARIOS</StyledCheckout.SectionTitle>

      {paypalPaymentsOptions?.map((option) => (
        <div
          className="text-gray-600 cursor-pointer"
          key={option.name}
          onClick={() => handlePaymentOptionChange({ target: { value: option.name } })}>
          <Radio value={option.name} checked={selectedPaymentOption?.name === option.name} />
          <label className="font-semibold">{option?.name}</label>
          <p className="font-light pl-6 text-xs">{option?.description}</p>
        </div>
      ))}
    </div>
  );
}

export default PaypalScenarios;
