import React from "react";
import StyledPageStep from "./StyledPageStep";

function PageStep({ pageNumber }) {
  const steps = [1, 2, 3];
  return (
    <StyledPageStep.Container>
      <StyledPageStep.InnerContainer>
        {steps?.map((step, index) => (
          <div id={`step-${index + 1}`} key={index} className="flex">
            <div>
              <p
                className={`border ${
                  pageNumber !== 2 ? "border-white" : "border-gray-600 "
                } rounded-full flex items-center justify-center ${
                  step === pageNumber
                    ? `w-6 h-6 text-base font-semibold border-0 ${
                        pageNumber !== 2
                          ? "bg-white text-teal-500"
                          : "bg-teal-500 text-white"
                      }   `
                    : `w-5 h-5 ${
                        pageNumber !== 2 ? "text-white" : "text-gray-600"
                      }`
                }`}
              >
                {step}
              </p>
            </div>
            {step !== 3 && (
              <div className=" flex items-center px-4">
                <hr
                  className={`${
                    pageNumber !== 2 ? "text-white" : "text-gray-600"
                  } w-20 `}
                />
              </div>
            )}
          </div>
        ))}
      </StyledPageStep.InnerContainer>
    </StyledPageStep.Container>
  );
}

export default PageStep;
