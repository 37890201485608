import React from "react";
import { Route, Routes } from "react-router-dom";
import appRoutes from "./utils/routes";
import { Layout } from "antd";

const { Content } = Layout;
function App() {
  return (
    <Layout className="w-full min-h-screen">
      <Content>
        <Routes>{React.Children.toArray(appRoutes?.map((route) => <Route path={route.path} element={route.element} />))}</Routes>
      </Content>
    </Layout>
  );
}

export default App;
