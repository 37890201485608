import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import PageStep from "../../components/pageStep/PageStep";
import { stripeCreatePayment } from "../../utils/apiCalls";
import { loadProduct, loadRandomUser } from "../../utils/helperFunctions";
import BackToCatalouge from "./BackToCatalouge";
import StyledPaymentCompletion from "./StyledPaymentCompletion";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const product = loadProduct();

function PaymentCompletion() {
  let query = useQuery();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const SetupIntentID = query.get("setup_intent");
  const amount = query.get("amount");
  const paymentMethod = query.get("paymentMethod");
  const randomUser = loadRandomUser();
  const orderStatus = message.status === "approved";
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    setLoading(true);

    const data = {
      setup_intent_id: SetupIntentID,
      currency: "usd",
      amount: amount,
      product_type: product?.product_type,
      trial_period: product?.trial_period,
      customer: randomUser,
    };

    stripeCreatePayment(data, paymentMethod, setMessage, setLoading, setTransactionId);
  }, []);

  return (
    <StyledPaymentCompletion.Container>
      {!loading ? (
        <>
          <div className="w-full bg-teal-500 h-60 rounded-b-full flex items-center">
            <PageStep pageNumber={3} />
          </div>
          <div className="-mt-10 m-auto flex justify-center shadow-lg w-1/2 h-96 bg-white rounded-2xl">
            <div
              className="w-1/3 rounded-tl-2xl rounded-bl-2xl"
              style={{
                backgroundImage: `url(${product?.thumbnail})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />

            <div className="w-3/4 px-16 py-20 space-y-8">
              <p className="text-3xl text-gray-700 font-semibold">{orderStatus ? "THANK YOU!" : "SOMETHING WENT WRONG"}</p>
              <div className="flex-col space-y-2">
                <p className="text-xs text-gray-500 font-semibold">ORDER STATUS</p>
                <div className="flex items-center space-x-2">
                  <p id="status-message" className="text-base text-gray-700 font-semibold">
                    {message?.statusMessage}
                  </p>

                  <div className="text-lg">
                    {orderStatus ? <BsCheckCircle className="text-teal-500" /> : <BsXCircle className="text-red-400" />}
                  </div>
                </div>
                {message?.subStatusMessage?.length > 0 && (
                  <p id="subStatus-message" className="text-[0.65rem] text-gray-500 font-semibold">
                    {message?.subStatusMessage}
                  </p>
                )}
              </div>
              <div className="flex-col space-y-2">
                <p className="text-xs text-gray-500 font-semibold">TRANSACTION ID</p>
                <p id="transaction-id" className="text-xs text-gray-700 font-semibold">
                  {transactionId}
                </p>
              </div>

              <BackToCatalouge />
            </div>
          </div>
        </>
      ) : (
        <StyledPaymentCompletion.Spinner>
          <Spin size="large" />
        </StyledPaymentCompletion.Spinner>
      )}
    </StyledPaymentCompletion.Container>
  );
}

export default PaymentCompletion;
