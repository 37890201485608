import Checkout from "../pages/checkout/Checkout";
import Catalogue from "../pages/shop/Catalogue";
import History from "../pages/History/History";
import PaymentCompletion from "../pages/paymentCompletion/PaymentCompletion";
import BraintreePaymentCompletion from "../pages/paymentCompletion/BraintreePaymentCompletion";
import ProcessingPayment from "../pages/checkout/paypal/ProcessingPayment";

const appRoutes = [
  {
    path: "/",
    element: <Catalogue />,
  },
  {
    path: "/braintree-shop",
    element: <Catalogue />,
  },
  {
    path: "/purchase-history",
    element: <History />,
  },
  {
    path: "/checkout/stripe",
    element: <Checkout />,
  },
  {
    path: "/checkout/braintree",
    element: <Checkout />,
  },
  {
    path: "/checkout/paypal",
    element: <Checkout />,
  },
  {
    path: "/checkout/bluesnap",
    element: <Checkout />,
  },
  {
    path: "/checkout/adyen",
    element: <Checkout />,
  },
  {
    path: "/paypal/processing-capture",
    element: <ProcessingPayment />,
  },
  {
    path: "/payment-successful/stripe",
    element: <PaymentCompletion />,
  },
  {
    path: "/payment-successful/braintree",
    element: <BraintreePaymentCompletion />,
  },
];

export default appRoutes;
