import React, { useEffect, useState } from "react";
import {
  createBluesnapVaultedShopperAndPay,
  createVaultedShopper,
} from "../../../utils/apiCalls";
import { Button } from "antd";
import { loadProduct } from "../../../utils/helperFunctions";

// Declare bluesnap globally
/* global bluesnap */

function BluesnapCheckoutComponent({ bluesnapToken }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadBluesnapScript = async () => {
      if (bluesnapToken) {
        try {
          // Load the Bluesnap script dynamically
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = "https://sandpay.bluesnap.com/web-sdk/5/bluesnap.js";

          document.head.appendChild(script);

          // Wait for the script to be loaded
          await new Promise((resolve) => {
            script.onload = resolve;
          });

          // bluesnap object should be available now
          if (typeof bluesnap !== "undefined") {
            var fieldStyle = {
              height: "2rem",
              "border-bottom": "solid 0.5px #e4e4e4",
              "background-color": "#f5f5f5",
            };
            var bsObj = {
              token: bluesnapToken,
              onFieldEventHandler: {
                // ... Your event handlers
              },
              style: {
                "#ccn": fieldStyle,
                "#cvv": fieldStyle,
                "#exp": fieldStyle,
                // Styling all inputs
                input: {
                  "font-size": "14px",
                  "font-family":
                    "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
                  "line-height": "1.42857143",
                  color: "#5a5a5a",
                  padding: "0.5rem",
                  height: "1rem",
                  "background-color": "#783434",
                },
                // Styling input state
                ":focus": {
                  color: "#565656",
                },
                "::placeholder": {
                  color: "#b3b3b3",
                },
              },
              accessibility: {
                fieldNames: {
                  ccn: "Card number input",
                  exp: "Card expiration date input",
                  cvv: "Card CVC/CVV input",
                },
              },
              ccnPlaceHolder: "1234 5678 9012 3456",
              cvvPlaceHolder: "123",
              expPlaceHolder: "MM/YY",
            };

            // Run the following command after Document Object Model (DOM) is fully loaded
            bluesnap.hostedPaymentFieldsCreate(bsObj);
            // Further logic to initialize Bluesnap or use bsObj
          } else {
            console.error("bluesnap is not defined");
          }
        } catch (error) {
          console.error("Error loading Bluesnap script:", error);
        }
      }
    };

    // Call the function to load Bluesnap script
    loadBluesnapScript();
  }, [bluesnapToken]);

  const handleButtonClick = () => {
    bluesnap.hostedPaymentFieldsSubmitData((callback) => {
      if (callback && callback.cardData) {
        var fraudSessionId = callback.transactionFraudInfo.fraudSessionId;

        const selectedProduct = loadProduct();
        createBluesnapVaultedShopperAndPay(
          bluesnapToken,
          selectedProduct,
          setLoading
        );

        console.log(
          "card type: " +
            callback.cardData.ccType +
            ", last 4 digits: " +
            callback.cardData.last4Digits +
            ", exp: " +
            callback.cardData.exp +
            ", issuing Country: " +
            callback.cardData.issuingCountry +
            ", isRegulatedCard: " +
            callback.cardData.isRegulatedCard +
            ", cardSubType: " +
            callback.cardData.cardSubType +
            ", binCategory: " +
            callback.cardData.binCategory +
            ", cardCategory: " +
            callback.cardData.cardCategory +
            ", ccBin: " +
            callback.cardData.ccBin +
            ", issuing bank: " +
            callback.cardData.ccIssuingBank +
            " and fraudSessionId: " +
            fraudSessionId +
            ", after that I can call final submit"
        );
        // You may want to submit the form or perform additional actions here
      } else if (callback && callback.error) {
        var errorArray = callback.error;
        for (let i in errorArray) {
          console.log(
            "Received error: tagId= " +
              errorArray[i].tagId +
              ", errorCode= " +
              errorArray[i].errorCode +
              ", errorDescription= " +
              errorArray[i].errorDescription
          );
        }
      }
    });
  };

  const fieldStyles = "h-10";

  return (
    <div className="max-h-fit">
      <div className=" text-gray-400 font-semibold py-2 space-y-3">
        <div className={fieldStyles} data-bluesnap="ccn">
          Card Number
        </div>
        <div className={fieldStyles} data-bluesnap="exp">
          Expiration Date
        </div>
        <div className={fieldStyles} data-bluesnap="cvv">
          CVV
        </div>
        <Button
          className="w-full"
          type="primary"
          loading={loading}
          onClick={handleButtonClick}>
          PAY NOW
        </Button>

        <div className="w-full flex justify-center">
          <a href="https://home.bluesnap.com/" target="_blank">
            <img
              src="/images/bluesnapBadge.png"
              alt="bluesnap-badge"
              width={150}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BluesnapCheckoutComponent;
