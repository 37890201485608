import React, { useState } from "react";
import { paypalCreateOrder } from "../../../utils/apiCalls";
import { loadProduct } from "../../../utils/helperFunctions";
import { GrPaypal } from "react-icons/gr";

function PaypalCheckoutButton() {
  const [loading, setLoading] = useState(false);
  const product = loadProduct();

  return (
    <div
      className="flex items-center justify-center space-x-2 mt-4 py-1 text-blue-500 border-2 border-blue-500 w-3/4 rounded-md cursor-pointer hover:bg-blue-500 hover:text-white transition ease-in-out duration-200"
      onClick={() => paypalCreateOrder(product, setLoading)}>
      {loading ? (
        <p>Please wait...</p>
      ) : (
        <>
          <p>Pay with</p>
          <GrPaypal className="text-xl" />
        </>
      )}
    </div>
  );
}

export default PaypalCheckoutButton;
