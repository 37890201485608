import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { paymentMethodOptions, paymentMethods } from "../../utils/constants";
import "./Products.css";
import { useEffect } from "react";
import { saveProduct } from "../../utils/helperFunctions";
import { paymentHandler } from "../../utils/apiCalls";

function PaymentSystemSelection({ product, setLoading, blockBraintree }) {
  const [selectedPaymentSystem, setSelectedPaymentSystem] = useState(null);
  const navigate = useNavigate();

  const data = {
    amount: product.price,
    currency: product?.currency,
  };

  const handleChange = (e) => {
    const selectedOption = paymentMethodOptions.find(
      (option) => option.value === e.target.value
    );
    setSelectedPaymentSystem(selectedOption);
  };

  useEffect(() => {
    const method = selectedPaymentSystem?.value;
    const navigatTo = () =>
      navigate(`/checkout/${method}`, {
        state: { method: method },
      });
    saveProduct(product);
    if (method === paymentMethods.paypal) {
      navigatTo();
    }
    if (method === paymentMethods.bluesnap) {
      navigatTo();
    }
    if (method === paymentMethods.adyen) {
      navigatTo();
    }
    if (
      method === paymentMethods.stripe ||
      method === paymentMethods.braintree
      // (!blockBraintree && method === paymentMethods.stripe) ||
      // (!blockBraintree && method === paymentMethods.braintree)
    ) {
      setLoading(true);
      paymentHandler(product, data, navigate, method, setLoading);
    }
  }, [selectedPaymentSystem]);

  return (
    <select
      id={product?.id}
      className="payment-system-dropdown"
      value={selectedPaymentSystem?.value}
      onChange={handleChange}
      style={{ width: "7rem" }}>
      {!selectedPaymentSystem && <option value="Choose">Choose</option>}
      {paymentMethodOptions?.map((option, idx) => (
        <option value={option.value} key={idx}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default PaymentSystemSelection;
