import React, { useState } from "react";
import { Tooltip } from "antd";
import StyledScenariosDropdown from "./StyledScenariosDropdown";

function ScenariosDropdown({
  scenariosList,
  selectedScenario,
  setSelectedScenario,
}) {
  const [show, setShow] = useState(false);

  const handleShowDropdown = () => {
    setShow(!show);
  };

  const handleChange = (idx) => {
    setSelectedScenario(scenariosList[idx]);
    setShow(!show);
  };

  return (
    <>
      <div className="relative" id="scenarios-dropdown-container">
        <StyledScenariosDropdown.ScenariosDropdown
          id="scenarios-dropdown"
          onClick={handleShowDropdown}>
          <StyledScenariosDropdown.ScenarioText
            id={`selected-scenario-${selectedScenario?.label}`}>
            {selectedScenario?.label}
          </StyledScenariosDropdown.ScenarioText>
          <StyledScenariosDropdown.Icon $show={show} />
        </StyledScenariosDropdown.ScenariosDropdown>

        <StyledScenariosDropdown.ScenariosOptions
          $show={show}
          id="scenarios-dropdown-options">
          {scenariosList?.map((scenario, idx) => (
            <Tooltip title={scenario?.value?.description} key={idx}>
              <StyledScenariosDropdown.Option
                id={`scenario-${scenario?.label}`}
                onClick={() => handleChange(idx)}>
                {scenario?.label}
              </StyledScenariosDropdown.Option>
            </Tooltip>
          ))}
        </StyledScenariosDropdown.ScenariosOptions>
      </div>
    </>
  );
}

export default ScenariosDropdown;
