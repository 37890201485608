import React from "react";
import { useLocation } from "react-router-dom";
import PageStep from "../../components/pageStep/PageStep";
import { loadProduct } from "../../utils/helperFunctions";
import BackToCatalouge from "./BackToCatalouge";
import StyledPaymentCompletion from "./StyledPaymentCompletion";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { paymentStatuses } from "../../utils/constants";

function BraintreePaymentCompletion() {
  const product = loadProduct();
  const location = useLocation();
  const paymentStatus = location?.state.status;
  const transactionId = location?.state.transaction_id;
  const orderStatus = paymentStatus?.status === paymentStatuses.approved;

  return (
    <StyledPaymentCompletion.Container>
      <>
        <div className="w-full bg-teal-500 h-60 rounded-b-full flex items-center">
          <PageStep pageNumber={3} />
        </div>
        <div className="-mt-10 m-auto flex justify-center shadow-lg w-1/2 h-96 bg-white rounded-2xl">
          <div
            className="w-1/3 rounded-tl-2xl rounded-bl-2xl"
            style={{
              backgroundImage: `url(${product?.thumbnail})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          />

          <div className="w-3/4 px-16 py-20 space-y-10">
            <p className="text-3xl text-gray-700 font-semibold">{orderStatus ? "THANK YOU!" : "SOMETHING WENT WRONG"}</p>
            <div className="flex-col space-y-2">
              <p className="text-xs text-gray-500 font-semibold">ORDER STATUS</p>
              <div className="flex items-center space-x-2">
                <p id="status-message" className="text-base text-gray-700 font-semibold">
                  {paymentStatus?.statusMessage}
                </p>
                <div className="text-lg">
                  {orderStatus ? <BsCheckCircle className="text-teal-500" /> : <BsXCircle className="text-red-400" />}
                </div>
              </div>
              {paymentStatus?.subStatusMessage?.length > 0 && (
                <p id="subStatus-message" className="text-[0.65rem] text-gray-500 font-semibold">
                  {paymentStatus?.subStatusMessage}
                </p>
              )}
            </div>

            <div className="flex-col space-y-2">
              <p className="text-xs text-gray-500 font-semibold">TRANSACTION ID</p>
              <p id="transaction-id" className="text-xs text-gray-700 font-semibold">
                {transactionId}
              </p>
            </div>
            <BackToCatalouge />
          </div>
        </div>
      </>
    </StyledPaymentCompletion.Container>
  );
}

export default BraintreePaymentCompletion;
