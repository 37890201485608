import { formatOptions, paymentStatuses } from "./constants";
import { notification } from "antd";

export const showErrorMessage = (error) => {
  notification.error({
    message: error?.code,
    description: error?.message,
  });
};

export const saveProduct = (product) => {
  localStorage.setItem("selected_product", JSON.stringify(product));
};

export const saveRandomUser = (user) => {
  localStorage.setItem("random_user", JSON.stringify(user));
};

export const loadProduct = () => {
  return JSON.parse(localStorage.getItem("selected_product"));
};

export const loadRandomUser = () => {
  return JSON.parse(localStorage.getItem("random_user"));
};

export const save = (key, data) => {
  localStorage.setItem(key, data);
};

export const load = (key) => {
  return localStorage.getItem(key);
};

export const savePaypalPaymentOption = (paymentOption) => {
  localStorage.setItem("paypal_payment_option", JSON.stringify(paymentOption));
};
export const loadPaypalPaymentOption = () => {
  return JSON.parse(localStorage.getItem("paypal_payment_option"));
};

export const randomUserNameFormatter = (name) => `${name?.first} ${name?.last}`;

export const randomUserAddressFormatter = (address) =>
  `${address?.street?.number} ${address?.street?.name} St. ${address?.city} ${address?.postcode} ${address?.country} `;

export const formatPrice = (price, action) => {
  if (action === formatOptions.display) return price;
  if (action === formatOptions.devide) return price / 100;
  if (action === formatOptions.multiply) return price * 100;
};

export const copyText = (text) => {
  navigator.clipboard.writeText(text);
};

export const cardResponseFormatter = (s) =>
  s[0].toUpperCase() + s.slice(1).replace("_", " ");

export const setupIntentStatus = (status, setter) => {
  switch (status) {
    case "succeeded":
      setter("Success! Your payment method has been saved.");
      break;

    case "processing":
      setter(
        "Processing payment details. We'll update you when processing is complete."
      );
      break;

    case "requires_payment_method":
      // Redirect your user back to your payment page to attempt collecting
      // payment again
      setter(
        "Failed to process payment details. Please try another payment method."
      );
      break;
    default:
      setter("Unknown state.");
  }
};

export const confirmSetupErrorType = (error, setter) => {
  if (error.type === "card_error" || error.type === "validation_error") {
    setter(error.message);
  } else {
    setter("An unexpected error occured.");
  }
};

export const cardNumberDisplay = (cardNumber) => {
  const cardFourDigits = (cardNumber, a, b) => (
    <p className="flex justify-evenly tracking-widest">
      {cardNumber?.toString().slice(a, b)}
    </p>
  );
  return (
    <div className="flex space-x-2">
      {cardFourDigits(cardNumber, 0, 4)}
      {cardFourDigits(cardNumber, 4, 8)}
      {cardFourDigits(cardNumber, 8, 12)}
      {cardFourDigits(cardNumber, 12, 16)}
    </div>
  );
};

export const paymentCompletionMessage = (status, errorMessage, braintree) => {
  if (!braintree) {
    return status === "succeeded" || status === "approved"
      ? "SUCCESFULL PAYMENT"
      : status === "active"
      ? "SUCCESFULL PAYMENT - SUBSCRIPTION ACTIVATED"
      : status === "trialing"
      ? "TRIAL HAS STARTED"
      : status;
  } else {
    return status === "succeeded" || status === "approved"
      ? "SUCCESFULL PAYMENT"
      : `${cardResponseFormatter(status)} - ${errorMessage}`;
  }
};

export const paymentStatusFormatter = (data) => {
  const {
    payment_system_response,
    bounce_response,
    error_message,
    call_bounce,
    status,
  } = data;

  let statusMessage = "";
  let subStatusMessage = "";

  if (payment_system_response === paymentStatuses.error) {
    statusMessage = "AN ERROR OCCURED";
    subStatusMessage = error_message;
  }
  if (payment_system_response?.toLowerCase() === paymentStatuses.approved) {
    statusMessage = "SUCCESFULL PAYMENT";
  }
  if (
    payment_system_response === paymentStatuses.failed &&
    bounce_response === paymentStatuses.approved
  ) {
    statusMessage = "APPROVED PAYMENT";
    subStatusMessage = "FAILED PAYMENT THAT GOT APPROVED BY BOUNCE";
  }
  if (
    payment_system_response === paymentStatuses.failed &&
    bounce_response === paymentStatuses.declined
  ) {
    statusMessage = "DECLINED PAYMENT";
    subStatusMessage = "FAILED PAYMENT THAT GOT REJECTED BY BOUNCE";
  }

  return { statusMessage, subStatusMessage, call_bounce, status };
};
