import axios from "axios";
import { apiUrl, formatOptions, productTypes } from "./constants";
import {
  formatPrice,
  loadRandomUser,
  paymentStatusFormatter,
  randomUserAddressFormatter,
  randomUserNameFormatter,
  save,
  saveProduct,
  saveRandomUser,
  showErrorMessage,
} from "./helperFunctions";
import iso3166 from "iso-3166-1-alpha-2";

const headers = { "Content-Type": "application/json" };
const bouncepay = window.bouncepay;

export const saveCatalougueProducts = (setter, loaderSetter) => {
  loaderSetter(true);
  axios
    .get("https://dummyjson.com/products")
    .then((res) => {
      const resProducts = res?.data?.products?.slice(0, 3);
      resProducts.forEach((product) => {
        Object.assign(product, {
          product_type: productTypes.checkout,
          trial_period: false,
        });
      });
      //For future use
      // const products = resProducts.concat(subscriptionItems);
      const products = resProducts;
      setter(products);
      loaderSetter(false);
    })
    .catch((err) => {
      loaderSetter(false);
      showErrorMessage(err);
    });
};

export const getRandomUser = () => {
  axios
    .get(
      "https://randomuser.me/api/?results=1&nat=us&inc=name,location,email,phone&noinfo"
    )
    .then((res) => {
      const results = res.data?.results[0];
      const randomUser = {
        name: randomUserNameFormatter(results?.name),
        email: results?.email,
        phone_number: results?.phone,
        address: randomUserAddressFormatter(results?.location),
        shipping_details: {
          first_name: results?.name.first,
          last_name: results?.name.last,
          city: results?.location.city,
          line_1: results?.location.street.name,
          line_2: results?.location.street.number.toString(),
          postal_code: results?.location.postcode,
          state: results?.location.state,
          country: iso3166.getCode(results?.location?.country),
        },
      };
      saveRandomUser(randomUser);
    })
    .catch((err) => showErrorMessage(err));
};

export const getCards = (method, setCardsList, setSelectedCard) => {
  axios
    .get(`${apiUrl}/cards/?payment_system=${method}`)
    .then((res) => {
      setCardsList(res.data);
      setSelectedCard(res.data[0]);
    })
    .catch((err) => showErrorMessage(err));
};

export function paymentHandler(product, data, navigate, method, setLoading) {
  axios
    .post(`${apiUrl}/${method}/setup`, data, { headers })
    .then((res) => {
      if (save && saveProduct) {
        save("clientSecret", res?.data?.client_secret);
        save("amount", formatPrice(product.price, formatOptions.multiply));
        saveProduct(product);
        setLoading(false);
        navigate(`/checkout/${method}`, { state: { method: method } });
      }
    })
    .catch((err) => {
      showErrorMessage(err);
      setLoading(false);
    });
}

export const stripeCreatePayment = (
  data,
  paymentMethod,
  setMessage,
  setLoading,
  setTransactionId
) => {
  const {
    customer: { shipping_details, ...customerExtra },
    ...extra
  } = data;
  axios
    .post(
      `${apiUrl}/${paymentMethod}/create-payment`,
      {
        ...extra,
        customer: customerExtra,
        shipping_details,
      },
      {
        headers,
      }
    )
    .then((res) => {
      setMessage(paymentStatusFormatter(res.data));
      bouncepay.connect("payment_intent", res.data.transaction_id);
      setLoading(false);
      setTransactionId(res?.data?.transaction_id);
    })
    .catch((err) => {
      setLoading(false);
      setMessage(`Declined - ${err?.response?.data}`);
      showErrorMessage(err);
    });
};

export const breaintreeCreatePayment = (
  data,
  paymentMethod,
  setMessage,
  setLoading,
  navigate
) => {
  const {
    customer: { shipping_details, ...customerExtra },
    ...extra
  } = data;
  axios
    .post(
      `${apiUrl}/${paymentMethod}/create-payment`,
      {
        ...extra,
        customer: customerExtra,
        shipping_details,
      },
      {
        headers,
      }
    )
    .then((res) => {
      setMessage(paymentStatusFormatter(res.data));
      navigate("/payment-successful/braintree", {
        state: {
          status: paymentStatusFormatter(res.data),
          transaction_id: res?.data?.transaction_id,
        },
      });
      bouncepay.connect("payment_intent", res?.data?.transaction_id);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      setMessage(`Declined - ${err?.response?.data}`);
      showErrorMessage(err);
    });
};

export const paypalCreateOrder = (product, setLoading) => {
  setLoading(true);
  const amountToSend = formatPrice(
    product.price,
    formatOptions.display
  ).toString();

  axios
    .post(`${apiUrl}/paypal/create-order/${amountToSend}`)
    .then((res) => {
      if (save && saveProduct) {
        save("amount", amountToSend);
        saveProduct(product);
        setLoading(false);
      }
      const approveHref = res?.data?.links?.find(
        (link) => link.rel === "approve"
      )?.href;
      window.location.href = approveHref;
    })
    .catch((err) => {
      showErrorMessage(err);
      setLoading(false);
    });
};

// Here we create a payment for and should use bouncepay.connect in then using transaction id
export const paypalCaptureOrder = (
  token,
  setOrderStatus,
  setTransactionId,
  setShowPaymentProccessed
) => {
  axios
    .post(`${apiUrl}/paypal/capture-order/${token}`)
    .then((res) => {
      setOrderStatus(res?.data?.status);
      setTransactionId(res?.data?.id);
      setShowPaymentProccessed(true);
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

export const paypalCaptureDeclinedOrder = (
  token,
  setOrderStatus,
  setTransactionId,
  setShowPaymentProccessed,
  declineCode
) => {
  axios
    .post(`${apiUrl}/paypal/capture-declined-order/${token}/${declineCode}`)
    .then((res) => {
      setOrderStatus(res?.data?.name);
      setTransactionId(res?.data?.id || res?.data?.debug_id);
      setShowPaymentProccessed(true);
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

export const paypalCaptureDeclinedToApprovedOrder = (
  token,
  setOrderStatus,
  setTransactionId,
  setShowPaymentProccessed,
  declineCode
) => {
  axios
    .post(`${apiUrl}/paypal/capture-declined-order/${token}/${declineCode}`)
    .then((res) => {
      setOrderStatus(res?.data?.name);
      setTransactionId(res?.data?.id || res?.data?.debug_id);
      setShowPaymentProccessed(true);
      paypalCaptureOrder(
        token,
        setOrderStatus,
        setTransactionId,
        setShowPaymentProccessed
      );
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

export const getBluesnapToken = (setBluesnapToken, setLoading) => {
  setLoading(true);
  axios
    .post(`${apiUrl}/bluesnap/get_token`)
    .then((res) => {
      setBluesnapToken(res?.data?.token);
      setLoading(false);
    })
    .catch((err) => {
      showErrorMessage(err);
      setLoading(false);
    });
};

// Here we create a payment for one-time shopper and should use bouncepay.connect in then
export const createBluesnapPayment = (bluesnapToken, product, setLoading) => {
  setLoading(true);
  axios
    .post(
      `${apiUrl}/bluesnap/create-payment/${bluesnapToken}/${product?.price}/${product?.currency}`
    )
    .then((res) => {
      setLoading(false);
      save("vaultedShopperId", res?.data?.vaultedShopperId);
    })
    .catch((err) => {
      showErrorMessage(err);
      setLoading(false);
    });
};

// Here we create a payment for a vaulted shopper and should use bouncepay.connect in then
export const createBluesnapPaymentWithVaultedShopper = (
  vaultedShopperId,
  product
) => {
  axios
    .post(
      `${apiUrl}/bluesnap/create-payment-vaulted-shopper/${vaultedShopperId}/${product?.price}/${product?.currency}`
    )
    .catch((err) => {
      showErrorMessage(err);
    });
};

// In this function we use the createBluesnapVaultedShopperPayment above to
//create payment after a vaulted shopper has been created and saved
export const createBluesnapVaultedShopperAndPay = (
  token,
  product,
  setLoading
) => {
  const userName = loadRandomUser()?.name;
  const [firstName, lastName] = userName.split(" ");

  setLoading(true);
  axios
    .post(
      `${apiUrl}/bluesnap/create-vaulted-shopper/${token}/${firstName}/${lastName}`
    )
    .then((res) => {
      const vaultedShopperId = res?.data?.vaultedShopperId;
      setLoading(false);
      if (vaultedShopperId) {
        save("vaultedShopperId", vaultedShopperId);
        createBluesnapPaymentWithVaultedShopper(product, vaultedShopperId);
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMessage(err);
    });
};

export const createVaultedShopper = (token) => {
  const userName = loadRandomUser()?.name;
  const [firstName, lastName] = userName.split(" ");

  axios
    .post(
      `${apiUrl}/bluesnap/create-vaulted-shopper/${token}/${firstName}/${lastName}`
    )
    .then((res) => {
      const vaultedShopperId = res?.data?.vaultedShopperId;
      save("vaultedShopperId", vaultedShopperId);
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

export const getVaultedShopper = (id) => {
  axios.get(`${apiUrl}/bluesnap/get-vaulted-shopper/${id}`).catch((err) => {
    showErrorMessage(err);
  });
};

export const getAdyenSessionToken = (
  setSessionDetails,
  product,
  user,
  setLoading
) => {
  setLoading(true);
  const price = product?.price * 100;
  const countryCode = user?.shipping_details?.country;
  axios
    .post(
      `${apiUrl}/adyen/get-session-token/${price}/${product.currency}/${countryCode}`
    )
    .then((res) => {
      const sessionDetails = {
        id: res?.data?.id,
        sessionData: res?.data?.sessionData,
        reference: res?.data?.reference,
        merchantAccount: res?.data?.merchantAccount,
        shopperReference: res?.data?.shopperReference,
        amount: res?.data?.amount,
        countryCode: res?.data?.countryCode,
      };
      setSessionDetails(sessionDetails);
      setLoading(false);
    })
    .catch((err) => {
      showErrorMessage(err);
      setLoading(false);
    });
};

export const getAdyenPaymenDetails = (
  merchantAccount,
  shopperReference,
  data
) => {
  axios
    .get(
      `${apiUrl}/adyen/payment-details/${merchantAccount}/${shopperReference}`
    )
    .then((res) => {
      const storedPaymentMethodId = res?.data?.storedPaymentMethods[0]?.id;
      const paymentDataObject = {
        ...data,
        storedPaymentMethodId: storedPaymentMethodId,
      };
      createAdyenDeclinedPayment(paymentDataObject);
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

export const createAdyenPayment = (data) => {
  axios
    .post(`${apiUrl}/adyen/create-payment`, data)
    .then((res) => {})
    .catch((err) => {
      showErrorMessage(err);
    });
};

export const createAdyenDeclinedPayment = (paymentDataObject) => {
  axios
    .post(`${apiUrl}/adyen/create-declined-payment`, paymentDataObject)
    .then((res) => {
      createAdyenPayment(paymentDataObject);
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};
