import React from "react";
import StyledPaymentCompletion from "./StyledPaymentCompletion";

function BackToCatalouge() {
  return (
    <StyledPaymentCompletion.BackToCatalouge.Container
      to="/"
      id="back-to-catalogue-button"
    >
      <p>BACK TO CATALOGUE</p>
    </StyledPaymentCompletion.BackToCatalouge.Container>
  );
}

export default BackToCatalouge;
