import tw from "tailwind-styled-components";
import { paymentMethods } from "../../utils/constants";

const StyledProducts = {
  ProductsContainer: tw.div`
  flex justify-center
  space-x-20 
  -mt-10
  `,

  SingleProductContainer: tw.div`
  w-72 h-[30rem] flex flex-col justify-between overflow-hidden
  shadow-lg rounded-2xl
  ${(p) => p.$index === 1 && "-mt-8"}
  ${(p) => p.$index === 2 && "-mt-8"}
`,

  ProductImg: tw.div`
w-full h-2/3 flex
`,

  ProductTitle: tw.p`
w-full self-end
py-2 pl-2
rounded-t-2xl
text-white font-bold  
`,

  BottomContainer: tw.div`
py-4 px-3 
`,

  TypeTitle: tw.p`text-[0.65rem] text-gray-500 font-semibold`,

  TypeDescription: tw.p` text-sm text-gray-800 font-semibold`,

  PaymentSystemSelection: tw.div`
  w-full flex items-center justify-between bg-teal-500 py-3 px-2
  text-xs font-semibold text-center
  `,

  PaymentSystemText: tw.p`
  w-1/2 text-white
  `,
};

export default StyledProducts;
