import React from "react";
import StyledProducts from "./StyledProducts";
import "./Products.css";
import PaymentSystemSelection from "./PaymentSystemSelection";

function Product({ product, index, setLoading }) {
  return (
    <StyledProducts.SingleProductContainer
      key={index}
      id={`catalogue-item-${product?.title}`}
      $index={index}>
      <StyledProducts.ProductImg
        style={{
          backgroundImage: `url(${product?.thumbnail})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}>
        <StyledProducts.ProductTitle>
          {product?.title}
        </StyledProducts.ProductTitle>
      </StyledProducts.ProductImg>

      <StyledProducts.BottomContainer>
        <StyledProducts.TypeTitle>
          {product?.lingopie ? "LINGOPIE - PRODUCT TYPE" : "PRODUCT TYPE"}
        </StyledProducts.TypeTitle>
        <StyledProducts.TypeDescription>
          {product?.description}
        </StyledProducts.TypeDescription>
      </StyledProducts.BottomContainer>

      <StyledProducts.PaymentSystemSelection>
        <StyledProducts.PaymentSystemText>
          Payment System
        </StyledProducts.PaymentSystemText>
        <div>
          <PaymentSystemSelection
            product={product}
            setLoading={setLoading}
            blockBraintree={product?.blockBraintree}
          />
        </div>
      </StyledProducts.PaymentSystemSelection>
    </StyledProducts.SingleProductContainer>
  );
}

export default Product;
