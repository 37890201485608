import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { loadRandomUser, saveRandomUser } from "../../utils/helperFunctions";
import { BiEdit } from "react-icons/bi";

function UserDetailsForm({ setUser }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const randomUser = loadRandomUser();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    setUser(values);
    saveRandomUser(values);
    setIsModalOpen(false);
  };

  const formInitialValues = {
    name: randomUser?.name,
    email: randomUser?.email,
    phone_number: randomUser?.phone_number,
    address: randomUser?.address,
  };

  return (
    <div>
      <BiEdit
        onClick={showModal}
        className="text-xl text-gray-600 cursor-pointer "
      />
      <Modal
        title="User details"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        {Object.keys(randomUser)?.length > 0 && (
          <Form
            onValuesChange={() => {
              setValueChanged(true);
            }}
            initialValues={formInitialValues}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>

            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>

            <Form.Item label="Phone number" name="phone_number">
              <Input />
            </Form.Item>

            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button disabled={!valueChanged} type="primary" htmlType="submit">
                Save changes
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default UserDetailsForm;
