import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dropin from "braintree-web-drop-in";
import { breaintreeCreatePayment } from "../../../utils/apiCalls";
import { load, loadRandomUser } from "../../../utils/helperFunctions";
import PayNowButton from "../PayNowButton";

function BraintreeChackoutForm({ clientSecret, paymentMethod, priceChange }) {
  const navigate = useNavigate();
  const randomUser = loadRandomUser();
  const [amount, setAmount] = useState(null);
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (load) {
      setAmount(load("amount"));
    }
  }, [priceChange]);

  useEffect(() => {
    const initializeBraintree = () =>
      dropin.create(
        {
          authorization: clientSecret,
          container: "#braintree-drop-in-div",
        },
        function (error, instance) {
          if (error) console.error(error);
          else setBraintreeInstance(instance);
        }
      );

    if (braintreeInstance) {
      braintreeInstance.teardown().then(() => {
        initializeBraintree();
      });
    } else {
      initializeBraintree();
    }
  }, [clientSecret]);

  const handlePayNow = () => {
    if (braintreeInstance) {
      setLoading(true);
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          console.error(error);
          setLoading(false);
        } else {
          const data = {
            amount: amount,
            payment_method_nonce: payload.nonce,
            customer: randomUser,
          };
          breaintreeCreatePayment(data, paymentMethod, setMessage, setLoading, navigate);
        }
      });
    }
  };

  return (
    <div>
      <div className="w-full flex items-center flex-col justify-center">
        <div id="braintree-drop-in-div" className={`w-full -mt-4 ${loading && "braintreeLoading"}`} />
        <PayNowButton disabled={loading} amount={amount} loading={loading} handlePayNow={handlePayNow} braintree />
        <p>{message}</p>
      </div>
    </div>
  );
}

export default BraintreeChackoutForm;
