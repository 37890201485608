import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  confirmSetupErrorType,
  load,
  setupIntentStatus,
} from "../../../utils/helperFunctions.js";
import { stripeRedirectUrl } from "../../../utils/constants.js";
import StyledCheckout from "../StyledCheckout.js";
import PayNowButton from "../PayNowButton.jsx";

export default function CheckoutForm({ amount, clientSecret, paymentMethod }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!load) {
      return;
    }

    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    if (!amount) {
      return;
    }
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.

      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      // setupIntentStatus(setupIntent.status, setMessage);
      setupIntentStatus(setupIntent.status, setMessage);
    });
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${stripeRedirectUrl}/payment-successful/stripe?amount=${amount}&paymentMethod=${paymentMethod}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    confirmSetupErrorType(error, setMessage);

    setIsLoading(false);
  };

  return (
    <div className="mt-5">
      <div>
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />
          <StyledCheckout.PayNowButtonContainer>
            <PayNowButton
              disabled={isLoading || !stripe || !elements}
              amount={amount}
              loading={isLoading}
            />

            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </StyledCheckout.PayNowButtonContainer>
        </form>
      </div>
    </div>
  );
}
