import React from "react";
import Spinner from "../../components/spinner/Spinner";
import { formatOptions } from "../../utils/constants";
import { formatPrice } from "../../utils/helperFunctions";
import StyledCheckout from "./StyledCheckout";

function PayNowButton({ disabled, amount, loading, handlePayNow, braintree }) {
  return (
    <StyledCheckout.PayButton
      $disabled={disabled}
      type="submit"
      id="submit"
      onClick={braintree && handlePayNow}
    >
      {!loading ? (
        <span id="button-text">
          PAY NOW ${formatPrice(amount, formatOptions.devide)}
        </span>
      ) : (
        <Spinner small />
      )}
    </StyledCheckout.PayButton>
  );
}

export default PayNowButton;
