import tw from "tailwind-styled-components";
import { RiArrowDownSLine } from "react-icons/ri";

const StyledScenariosDropdown = {
  ScenariosDropdown: tw.div`flex items-center justify-between border border-gray-300 rounded-md py-1 px-2 cursor-pointer`,
  ScenarioText: tw.p`text-gray-600 font-semibold`,
  Icon: tw(RiArrowDownSLine)`
  text-gray-600 text-xl transition-all ease-in-out duration-300
  ${(p) => p.$show && "rotate-180"}
  `,
  ScenariosOptions: tw.div`
  border border-gray-300 rounded-md py-2 px-2 space-y-2 mt-1 absolute w-full z-10 bg-white transition-all ease-in-out duration-150
  ${(p) => (p.$show ? "block opacity-100" : "hidden opacity-0")}
  `,
  Option: tw.p`
  text-gray-600 font-semibold cursor-pointer hover:text-gray-800
  `,
};

export default StyledScenariosDropdown;
