import React, { useEffect, useState } from "react";
import {
  formatOptions,
  paymentMethods,
  scenarios,
} from "../../../utils/constants";
import {
  cardNumberDisplay,
  copyText,
  formatPrice,
  save,
} from "../../../utils/helperFunctions";
import StyledCheckout from "../StyledCheckout";
import { MdContentCopy } from "react-icons/md";
import { getCards } from "../../../utils/apiCalls";
import ScenariosDropdown from "../../../components/scenariosDropdown/ScenariosDropdown";
import CheckoutCreditCard from "./CheckoutCreditCard";

function CardOptions({
  paymentMethod,
  selectedCard,
  setSelectedCard,
  togglePriceChange,
}) {
  const scenariosList = scenarios(paymentMethod);
  const [cardsList, setCardsList] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(scenariosList[0]);

  useEffect(() => {
    getCards(paymentMethod, setCardsList, setSelectedCard);
  }, []);

  useEffect(() => {
    setSelectedCard(cardsList[selectedScenario?.value?.cardPosition]);

    save(
      "amount",
      formatPrice(selectedScenario?.value?.price, formatOptions.multiply)
    );
    togglePriceChange();
  }, [selectedScenario]);

  return (
    <div>
      <StyledCheckout.SectionTitle>SCENARIOS</StyledCheckout.SectionTitle>

      <div className="w-full mt-4">
        <ScenariosDropdown
          scenariosList={scenariosList}
          selectedScenario={selectedScenario}
          setSelectedScenario={setSelectedScenario}
        />
      </div>

      {selectedCard && (
        <div className="flex flex-col items-center xl:flex-row">
          <CheckoutCreditCard selectedCard={selectedCard} />

          {Object.keys(selectedCard).length > 0 && (
            <StyledCheckout.CopySection>
              <StyledCheckout.CopyItem
                id="copy-card-number"
                onClick={() => copyText(selectedCard?.card_number)}>
                <MdContentCopy className="text-md" />
                <StyledCheckout.CopyItemText>
                  Card Number
                </StyledCheckout.CopyItemText>
              </StyledCheckout.CopyItem>
              <StyledCheckout.CopyItem
                id="copy-expiration-date"
                onClick={() => copyText(selectedCard?.expiration_date)}>
                <MdContentCopy className="text-md" />
                <StyledCheckout.CopyItemText>
                  Exp Date
                </StyledCheckout.CopyItemText>
              </StyledCheckout.CopyItem>
              {paymentMethod !== paymentMethods.braintree && (
                <StyledCheckout.CopyItem
                  id="copy-cvc"
                  onClick={() => copyText(selectedCard?.cvc)}>
                  <MdContentCopy className="text-md" />
                  <StyledCheckout.CopyItemText>CVC</StyledCheckout.CopyItemText>
                </StyledCheckout.CopyItem>
              )}
            </StyledCheckout.CopySection>
          )}
        </div>
      )}
    </div>
  );
}

export default CardOptions;
