import React, { useEffect, useState } from "react";
import {
  copyText,
  load,
  loadProduct,
  loadRandomUser,
} from "../../utils/helperFunctions";
import { useLocation } from "react-router-dom";
import BackToCatalouge from "../paymentCompletion/BackToCatalouge";
import PageStep from "../../components/pageStep/PageStep";
import StyledCheckout from "./StyledCheckout";
import Scenarios from "./sections/Scenarios";
import PaymentDetails from "./sections/PaymentDetails";
import ShippingDetails from "./sections/ShippingDetails";
import YourOrder from "./sections/YourOrder";
import { paymentMethods } from "../../utils/constants";
import PaypalScenarios from "./sections/PaypalScenarios";

function Checkout() {
  const [user, setUser] = useState(null);
  const [amount, setAmount] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [selectedCard, setSelectedCard] = useState({});
  const [priceChange, setPriceChange] = useState(false);
  const location = useLocation();
  const paymentMethod = location?.state?.method;
  const selectedProduct = loadProduct();

  useEffect(() => {
    setUser(loadRandomUser());
    if (load) {
      setClientSecret(load("clientSecret"));
      setAmount(load("amount"));
    }
  }, []);

  useEffect(() => {
    if (paymentMethod != paymentMethods.paypal) {
      copyText(selectedCard?.card_number);
    }
  }, [selectedCard]);

  const togglePriceChange = () => {
    setPriceChange(!priceChange);
  };

  useEffect(() => {
    if (load) {
      setAmount(load("amount"));
    }
  }, [priceChange]);

  return (
    <StyledCheckout.CheckoutContainer>
      <PageStep pageNumber={2} />
      {clientSecret && amount && (
        <StyledCheckout.CheckoutInnerContainer>
          <StyledCheckout.CheckoutTitle>Checkout</StyledCheckout.CheckoutTitle>

          <StyledCheckout.CheckoutDetailsContainer>
            <StyledCheckout.RightContainer>
              <ShippingDetails user={user} setUser={setUser} />

              <YourOrder
                selectedProduct={selectedProduct}
                amount={amount}
                paymentMethod={paymentMethod}
              />

              <BackToCatalouge />
            </StyledCheckout.RightContainer>

            <StyledCheckout.LeftContainer>
              {paymentMethod ===
              paymentMethods.paypal ? null : paymentMethod ===
                paymentMethods.paypal ? (
                <PaypalScenarios />
              ) : paymentMethod === paymentMethods.bluesnap ? null : (
                <Scenarios
                  paymentMethod={paymentMethod}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  togglePriceChange={togglePriceChange}
                />
              )}

              <PaymentDetails
                paymentMethod={paymentMethod}
                clientSecret={clientSecret}
                amount={amount}
                priceChange={priceChange}
              />
            </StyledCheckout.LeftContainer>
          </StyledCheckout.CheckoutDetailsContainer>
        </StyledCheckout.CheckoutInnerContainer>
      )}
    </StyledCheckout.CheckoutContainer>
  );
}

export default Checkout;
